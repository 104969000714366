import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";

@Component({
	selector: "eth-icon",
	standalone: true,
	imports: [CommonModule, MatIconModule],
	templateUrl: "./icon.component.html",
	styleUrl: "./icon.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EthIconComponent {
	@Input({ required: true }) name!: string;
	@Input() filled = false;
	@Input() spin = false;
}
